import { Spin } from 'antd'
import React, { lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { RootState } from '../redux/store'

// 懒加载 防止页面闪烁 加载完样式才显示 https://blog.csdn.net/qq_41632427/article/details/126316701
// v6跳转页面传参 https://segmentfault.com/a/1190000042309247
const Login = lazy(() => import('../pages/login/Login'))
const Index = lazy(() => import('../pages/index/Index'))
const lazyload = (children: any) => {
    return <Suspense>
        {children}
    </Suspense>
}

//定义一个路由拦截函数
function RequireAuth({children}:any) {
    return (localStorage.getItem("uid") ? children : <Navigate to="/login" replace />)
}

interface IProp{
    isLoading:boolean
}
const IndexVouter = (props:IProp) => {
    return (
        <Router>
            <Spin spinning={props.isLoading} tip="加载中" size="large">
                <Routes>
                    <Route path="/login" element={lazyload(<Login />)} />
                    <Route path='/*' element={<RequireAuth children={lazyload(<Index/>)}/>}/>
                </Routes>
            </Spin>
        </Router>
    )
}

const mapStateToProps = ({ LoadingReducer: { isLoading } }:RootState) => ({
    isLoading
})
export default connect(mapStateToProps)(IndexVouter)