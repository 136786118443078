interface actionType {
    type: string;
    page: number;
}
interface IState{
    currentOrder:number
}
export const OrderReducer = (prevState:IState={
    currentOrder:1
},action:actionType)=>{
        // console.log(action)
    let {type,page} =action
    switch(type){
        case "change_order_page":
            let newstate = {...prevState}
            newstate.currentOrder = page
            return newstate
        default:
            return prevState
    }
}