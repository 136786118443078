interface actionType {
    type: string;
    payload: boolean;
}
interface IState{
    isLoading:boolean
}
export const LoadingReducer = (prevState:IState={
    isLoading:false
},action:actionType)=>{
        // console.log(action)
    let {type,payload} =action

    switch(type){
        case "change_loading":
            let newstate = {...prevState}
            newstate.isLoading = payload
            return newstate
        default:
            return prevState
    }
}