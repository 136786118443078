interface actionType {
    type: string;
    page: number;
}
interface IState{
    currentLabel:number
}
export const LabelReducer = (prevState:IState={
    currentLabel:1
},action:actionType)=>{
        // console.log(action)
    let {type,page} =action
    switch(type){
        case "change_page":
            let newstate = {...prevState}
            newstate.currentLabel = page
            return newstate
        default:
            return prevState
    }
}