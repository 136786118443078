import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './config/url'
import './assets/scss/global.scss'
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN'; 

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={{ token: { colorPrimary: '#3672F6' } }} locale={ zhCN }>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

