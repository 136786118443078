import React from 'react';
import './rem'
import IndexVouter from './vouter/IndexVouter';
import { Provider } from 'react-redux';
import store from './redux/store';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <IndexVouter/>
      </div>
    </Provider>
  );
}

export default App;
