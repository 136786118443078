import { legacy_createStore as createStore, combineReducers } from 'redux'
import { LoadingReducer } from './reducers/LoadingReducer'
import { LabelReducer } from './reducers/LabelReducer'
import { OrderReducer } from './reducers/OrderReducer'

const reducer = combineReducers({
    LoadingReducer,
    LabelReducer,
    OrderReducer
})
const store = createStore(reducer)

// 从 store 本身推断出 `RootState` 和 `AppDispatch` 类型
export type RootState = ReturnType<typeof store.getState>
// 推断出类型: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store